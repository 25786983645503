.container:not(.is-max-desktop):not(.is-max-widescreen) {
  max-width: 1680px;
}

.navbar {
  flex-wrap: wrap;
}

.navbar-burger {
  height: auto;
}

.navbar-menu {
  flex-shrink: 1;

  .navbar-item {
    text-transform: capitalize;
  }
}

.navbar-start {
  flex-wrap: wrap;
}

@media(max-width: 767px) {
  .narrow-reverse-columns {
    flex-direction: column-reverse;
    display: flex;
    padding-top: 0;

    :nth-child(2).column {
      padding-top: 0;
      margin-top: -1.5em;
    }
  }
}

thead th span {
  font-size: 0.8rem;
}

tbody tr th {
  font-weight: normal;
}

tbody tr {
  transition: background-color 1s ease;

  &:hover {
    transition: background-color 0s 0s;
  }
}

th.rating-title, td.rating  {
  text-align: center;
}

th.sort {
  cursor: pointer;
}

tr.totals {
  font-weight: bold;
  background-color: transparent !important;

  td {
    border: none;
  }
}

td {
  text-transform: capitalize;
  a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

.table.has-sticky-header {
  thead th {
    padding: 1rem 0.5rem;
    background-color: #fff;
    position: sticky;
    top: -1px;
    z-index: 10;
  }
}

table.regions td:nth-child(4), 
table.regions th:nth-child(4) {
  display: none;
}

table.regions.show td:nth-child(4), 
table.regions.show th:nth-child(4) {
  display: table-cell;
}

.show-means {
  font-size: 0.8rem;
  text-transform: lowercase;
}

ul.search-results {
  list-style-type: none;
  margin: 0;
  padding: 0.5rem 0;

  li a {
    display: block;
    position: relative;
    padding: 0.3rem 5rem 0.3rem 0.75rem;

    &:hover {
      background-color: #fafafa;
    }
  }

  .country {
    background-color: #eee;
    border-radius: 5px;
    color: #000;
    position: absolute;
    top: 5px;
    right: 0.75rem;
    font-size: 0.75rem;
    letter-spacing: 0.02rem;
    padding: .2rem .5rem;      
    text-transform: capitalize;
  }
}

.highlight {
  background-color: #fff9c4 !important;
}

.hidden {
  display: none;
}
